import { Suspense, useCallback, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getUsersMe, postAuthRefresh } from "api/user.request";
import { setHeader } from "api/config";
import { useConfirm } from "utils/helper";
import { accessTokenState, isLoginState, userState } from "store/user";
import {
  SignupPage,
  LoginPage,
  LogoutPage,
  CompletePage,
  FindEmailPage,
  FindPasswordPage,
  TossPaymentCheckingPage,
} from "./pages";
import { LOCAL_STORAGE_KEY } from "config/const.ts";
import Home from "pages/Home";
import "./firebase-messaging-sw.ts";

const Fallback = () => <div />;

function App() {
  const setIsLogin = useSetRecoilState(isLoginState);
  const [user, setUser] = useRecoilState(userState);
  const setAccessToken = useSetRecoilState(accessTokenState);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * 새로운 토큰 받아오기
   * @토큰정책
   * access_token은 발급 받은 후 24시간(정책에 따라 변동 가능)동안 유효합니다.
   * refresh token은 한달간 유효하며, refresh token 만료가 1주일 이상 남은 시점에서 사용자 토큰 갱신 요청을 하면 갱신된 access token만 반환됩니다.
   * refresh token 만료가 1주일 미만 남은 시점에서 사용자 토큰 갱신 요청을 하면 갱신된 access token과 갱신된 refresh token이 함께 반환됩니다.
   */
  const refresh = useCallback(async () => {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.refreshTokenKey);

    if (refreshToken) {
      const { data } = await postAuthRefresh({ refreshToken });

      if (data.data) {
        const { type, accessToken } = data.data.payload;
        setHeader(`${type} ${accessToken}`);
        setAccessToken(accessToken);
        setIsLogin(true);

        if (data.data.payload.refreshToken) {
          localStorage.setItem(LOCAL_STORAGE_KEY.refreshTokenKey, data.data.payload.refreshToken);
        }
      }
    }
  }, [setIsLogin, setAccessToken]);

  const me = useCallback(async () => {
    const {
      data: { data },
    } = await getUsersMe();

    if (data) {
      setUser(data);
    }
  }, [setUser]);

  const initialize = useCallback(async () => {
    try {
      await refresh();
      await me();
    } catch (err) {
      confirm.ok("인증정보가 만료되었습니다.<br/>로그인 화면으로 이동합니다.", function () {
        navigate("/login");
      });
    }
  }, [refresh, me, confirm]);

  useEffect(() => {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.refreshTokenKey);

    if (!refreshToken && !user && location.pathname === "/") {
      navigate("/login");
      return;
    }

    if (!user && location.pathname === "/") {
      navigate("/broadcast");
      return;
    }

    if (
      !user &&
      location.pathname !== "/login" &&
      location.pathname !== "/logout" &&
      location.pathname !== "/signup" &&
      location.pathname !== "/complete" &&
      location.pathname !== "/find-email" &&
      location.pathname !== "/find-password" &&
      location.pathname !== "/toss/payment-checking"
    ) {
      initialize();
    }

    console.log("App Initialize");
  }, [user, initialize, location, navigate]);

  return (
    <div className="App">
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/complete" element={<CompletePage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/find-email" element={<FindEmailPage />} />
          <Route path="/find-password" element={<FindPasswordPage />} />
          <Route path="/toss/payment-checking" element={<TossPaymentCheckingPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
