import { AxiosPromise } from "axios";
import env from "config/env";
import { IOrderMessageData, IOrderComment, IOpponent } from "interfaces/order.interface";
import request, { TodoResponse, Response } from "./config";
import { SeverTalkPlusUser } from "interfaces/guest.interface";
const BASE_URL = env.SOCKET_URL;

export const getTalkPlusLoginToken = () =>
  request({ baseURL: BASE_URL, url: "/talkplus/auth/login", method: "POST" }) as AxiosPromise<
    Response<SeverTalkPlusUser>
  >;

export const postFCMToken = (token: string, os: string) =>
  request({
    baseURL: BASE_URL,
    url: "/pushes/fcm",
    method: "POST",
    data: {
      token,
      os,
    },
  });

type PostDataType = {
  message: {
    type: "IMAGE" | "TEXT";
    body: string;
  };
  additionalData: {
    chatRoomId: string;
    sendUserName: string;
  };
};

export const postSendAlarm = (userId: string, data: PostDataType) =>
  request({
    baseURL: BASE_URL,
    url: `/chat/users/${userId}`,
    method: "POST",
    data,
  });

export const createRoom = (guestId: number, sellerId: number) =>
  request({
    baseURL: BASE_URL,
    url: "/rooms",
    method: "POST",
    data: {
      guestId,
      sellerId,
    },
  }) as AxiosPromise<Response<{ orderChatRoomId: number }>>;

export const getOrderMessage = (roomId: string, params: { size: number; [key: string]: any }) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/messages`,
    method: "GET",
    params,
  }) as AxiosPromise<TodoResponse<IOrderComment[], { cursor: string }>>;

export const postOrderMessage = (roomId: string, data: IOrderMessageData) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/messages`,
    method: "POST",
    data,
  });

export const getChatPartners = (roomId: string) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/users`,
    method: "GET",
  }) as AxiosPromise<Response<IOpponent[]>>;

export const postChatRead = (roomId: string | number) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/messages/read`,
    method: "POST",
  });
