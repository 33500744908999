import firebase from "firebase/app";
import "firebase/messaging";
import env from "config/env";
import { mobileOS } from "utils/lib";
import { postFCMToken } from "api/socket.request";
import { LOCAL_STORAGE_KEY } from "config/const";

const firebaseConfig = {
  apiKey: env.FIREBASE.API_KEY,
  authDomain: env.FIREBASE.AUTH_DOMAIN,
  projectId: env.FIREBASE.PROJECT_ID,
  storageBucket: env.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE.MEASUREMENT_ID,
  appId: env.FIREBASE.APP_ID,
};

firebase.initializeApp(firebaseConfig);

let messaging: firebase.messaging.Messaging | undefined; // messaging이 초기화되지 않을 가능성을 고려하여 `undefined`를 허용

const os = mobileOS();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      // 최초 1회 업데이트
      registration.update();

      // 서비스 워커가 활성화되었을 때
      if (registration.active) {
        console.log("서비스워커가 활성화 되었습니다.");
        registration.active.postMessage({ os: os });
      }

      // 서비스 워커 업데이트 체크
      registration.onupdatefound = () => {
        const newWorker = registration.installing;
        if (!newWorker) return;

        newWorker.onstatechange = () => {
          if (newWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // 새로운 서비스 워커가 설치되었고 페이지가 리로드될 준비가 되었음을 알림
              console.log("New version available! Refreshing...");
              window.location.reload(); // 페이지 리로드하여 새로운 서비스 워커 사용
            } else {
              // 페이지를 새로고침하지 않고도 서비스 워커가 활성화됨
              console.log("Content is cached for offline use.");
            }
          }
        };
      };

      // 주기적인 서비스 워커 업데이트 체크
      const intervalId = setInterval(() => {
        registration
          .update()
          .then(() => {
            console.log("Service Worker updated");
          })
          .catch((error) => {
            console.error("Service Worker update failed:", error);
          });
      }, 60000); // 1분마다 업데이트 체크

      // 컴포넌트 언마운트 시 interval 정리
      return () => clearInterval(intervalId);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

// 파이어베이스 메시징을 지원하고 모바일 os인 경우 실행
if (firebase.messaging.isSupported() && os) {
  messaging = firebase.messaging();
}

const isPWA = window.matchMedia("(display-mode: standalone)").matches;

if (isPWA) {
  // PWA로 실행 중일 때 theme컬러 변경
  document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#EF528F");
}

export default async function requestPermission(closeHandler: () => void) {
  // Firebase Messaging이 현재 브라우저에서 지원되며 모바일인지 확인
  if (!firebase.messaging.isSupported() || !os) return;

  await navigator.serviceWorker.ready;

  try {
    const permission = await Notification.requestPermission();

    closeHandler();

    if (permission === "granted") {
      const pushToken = await messaging?.getToken({ vapidKey: env.FIREBASE.VAPID_KEY });
      if (pushToken && isPWA) {
        try {
          await postFCMToken(pushToken, os);
          localStorage.setItem(LOCAL_STORAGE_KEY.pushToken, pushToken);
          console.log("새로운 토큰 등록 성공");
        } catch (err) {
          console.log("토큰 등록 요청 중 에러 발생");
        }
      }
    } else if (permission === "denied") {
      console.log("푸시 권한 차단");
    }
  } catch (err) {
    console.log("푸시 토큰 가져오는 중에 에러 발생");
  }
}

navigator.permissions.query({ name: "notifications" }).then(async (permissionStatus) => {
  console.log(permissionStatus.state);
  if (permissionStatus.state === "granted") {
    console.log("알림 권한 허용됨");
    // 설정 창에서 상태가 변경되었을 때
    try {
      // 새로운 push 토큰을 받아옵니다.
      const pushToken = await messaging?.getToken({ vapidKey: env.FIREBASE.VAPID_KEY });
      // 이전에 저장된 push토큰을 가져옵니다.
      const prevPushToken = localStorage.getItem(LOCAL_STORAGE_KEY.pushToken);

      const token = localStorage.getItem(LOCAL_STORAGE_KEY.refreshTokenKey);

      // 푸시 토큰이 없다면 얼리 리턴함으로써 토큰을 저장하지 않음
      if (!pushToken) return;

      // 로그인중
      // 지원하는 os인 경우
      // PWA일 경우에만
      // 이전 푸시 토큰과 다른 경우 새로운 토큰 등록
      if (token && os && prevPushToken !== pushToken && isPWA) {
        try {
          await postFCMToken(pushToken, os);
        } catch (err) {
          console.log("토큰 등록 요청 중 에러 발생");
        }
      } else {
        // 로그인 상태가 아니고 푸시 토큰이 있다면 토큰 스토리지에 저장
        console.log("알림 권한 거부됨");
        localStorage.setItem(LOCAL_STORAGE_KEY.pushToken, pushToken);
      }
    } catch (err) {
      console.error("푸시 토큰 요청 중 에러 발생:", err);
    }
  } else if (permissionStatus.state === "denied") {
    console.log("알림 허용 안 해요");
  }
});
